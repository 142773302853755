<template>
  <HourglassLoader :loading="loading">
    <section>
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-block">
          <h2 class="h4">Activity Details</h2>
        </div>

        <div class="btn-toolbar">
          <button type="button" class="btn btn-sm btn-outline-primary" @click="RefreshData">Reload</button>
        </div>
      </div>

      <div v-if="data" class="row">
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h4 class="text-gray">Created At</h4>
              <h5 class="fw-bolder">{{ data.created_at }}</h5>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Status</h4>
                <h6>{{ data.status }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">WalletId</h4>
                <h6>{{ data.fiat_wallet_id }}</h6>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-4">
                <h4 class="text-gray">UserId</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.id }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Email</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>{{ data.User.email }}</h6>
                </router-link>
              </div>
              <div class="col-12 col-md-4">
                <h4 class="text-gray">Name</h4>
                <router-link :to="`/users/${data.User.id}`">
                  <h6>
                    {{ data.User.Profile.first_name }}
                    {{ data.User.Profile.last_name }}
                  </h6>
                </router-link>
              </div>
            </div>

            <div class="row text-center py-3">
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Comment</h4>
                <h6>{{ data.description ? data.description : "No Description" }}</h6>
              </div>
              <div class="col-12 col-md-6">
                <h4 class="text-gray">Remark</h4>
                <h6>{{ data.remark ? data.remark : "No Remark" }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Amount Information</h6>
            </div>

            <div class="row text-center py-3">
              <div class="col-4">
                <h4 class="text-gray">Code</h4>
                <h6>{{ data.FiatCurrency.code }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Name</h4>
                <h6>{{ data.FiatCurrency.name }}</h6>
              </div>
              <div class="col-4">
                <h4 class="text-gray">Amount</h4>
                <h6>{{ data.amount }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 pb-5">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Credit Information</h6>
            </div>

            <div class="row justify-content-center text-center py-3">
              <div class="col-6">
                <h4 class="text-gray">Account Number</h4>
                <h6>{{ data.BankAccount.account_number }}</h6>
              </div>
              <div class="col-6">
                <h4 class="text-gray">Bank Code</h4>
                <h6>{{ data.BankAccount.bank_code }}</h6>
              </div>
            </div>
            <div class="row mt-4 gy-4 border justify-content-center text-center py-3">
              <div v-for="(value, key) in data.BankAccount.details" class="col-4" :key="key">
                <h4 class="text-gray">{{ key }}</h4>
                <h6>{{ value }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5 mt-md-0">
          <div class="card card-body border-light shadow-sm">
            <div class="text-center">
              <h6 class="text-gray">Payment Attempts</h6>
            </div>

            <div class="card card-body border-light shadow-sm table-wrapper table-responsive pt-0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Reference</th>
                    <th>FLW ID</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dt, index) in data.PaymentOutwards" :key="index">
                    <td>{{ dt.id }}</td>
                    <td>{{ dt.reference }}</td>
                    <td>{{ dt.flw_tx_id }}</td>
                    <td>{{ dt.status }}</td>
                    <td>{{ dt.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h1 class="text-danger text-center">No Data!</h1>
        </div>
      </div>
    </section>
  </HourglassLoader>
</template>
  
  <script lang="ts">
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent, inject } from "vue";
import { apiGet, apiPost } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActivityDetail",
  components: {
    HourglassLoader,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const RequestConfirmation = inject("RequestConfirmation");

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/withdrawals/transactions/${route.params.transactionId}`
        );
        data.value = response.data.data.transaction;
        Toast.fire({
          icon: "success",
          title: "Transaction Detail Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const ApproveTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/approve`
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Approved Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ProcessTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/retry`,
          { status: "PROCESSING" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction Processing",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const ManualTransaction = async () => {
      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/manual`,
          { status: "MANUAL" }
        );
        Toast.fire({
          icon: "info",
          title: "Transaction now marked successful",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to approve manually: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    const DeclineTransaction = async () => {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Enter Reason/Comment for declining",
        inputPlaceholder: "Type your reason/comment here...",
        inputAttributes: {
          "aria-label": "Type your reason/comment here",
        },
        showCancelButton: true,
      });

      if (!text) {
        return;
      }

      loading.value = true;
      try {
        await apiPost(
          `/admin/withdrawals/transactions/${route.params.transactionId}/decline`,
          { comments: text, status: "FAILED" }
        );
        Toast.fire({
          icon: "success",
          title: "Transaction Declined Successfully",
        }).then();
        await RefreshData();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to decline: " + e.message,
        }).then();
        loading.value = false;
      }
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      ApproveTransaction,
      DeclineTransaction,
      ProcessTransaction,
      ManualTransaction,
      RequestConfirmation,
      route,
      data,
      loading,
    };
  },
});
</script>
  
  <style scoped></style>
  